import { combineReducers } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import accountReducer from "./accountsReducer"
import jobsReducer from "./jobsReducer"


const reducers = combineReducers({
  account: persistReducer(
    {
      key: "account",
      storage,
      keyPrefix: "datta-",
    },
    accountReducer
  ),
  jobsReducer,
})

export default reducers
