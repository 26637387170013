import "./App.css";
import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./Router/PrivateRoute";
import ProtectedRoute from "./Router/ProtectedRoute";
import PublicRoute from "./Router/PublicRoute";
import { PropagateLoader } from "react-spinners";

const SpinnerFallback = () => (
  <div className="lazyLoader">
    <img
      className=" pulse"
      src="https://storage.googleapis.com/varal-habot-vault-marketplace-10032022/images/updated%20trans-Habot-logo-png.png"
      height="55"
      alt="Logo-Habot"
    />
    <PropagateLoader size={20} color="#072F57" />
  </div>
);
const LazyAboutUs = lazy(() => import("./Components/About/About"));
const LazyBlog = lazy(() => import("./Components/Blog/Blog"));
const LazyBlogSummary = lazy(() => import("./Components/Blog/BlogSummary"));
const LazyBlogNavigating = lazy(() =>
  import("./Components/Blog/BlogNavigating")
);

const LazyInternalServerError = lazy(() =>
  import("./Components/500/InternalServerError")
);
const LazyNotFound = lazy(() => import("./Components/404/NotFound"));

const LazyWelcomePage = lazy(() =>
  import("./Components/WelcomePage/WelcomePage")
);
const LazyAccessibility = lazy(() =>
  import("./Components/Accessibility/accessibility.jsx")
);

const LazyAppliedJobList = lazy(() =>
  import("./Components/AppliedJobList/AppliedJobList")
);

const LazyBuyerReviews = lazy(() =>
  import("./Components/BuyerReviews/BuyerReviews")
);
const LazySupplierReviews = lazy(() =>
  import("./Components/SupplierReview/SupplierReview.jsx")
);

const LazyBuyerToSupplier = lazy(() =>
  import("./Components/BuyerToSupplier/BuyerToSupplier")
);

const LazyDataPrivacy = lazy(() =>
  import("./Components/DataPrivacy/DataPrivacy")
);

const LazyDeatailsJobsPostOne = lazy(() =>
  import("./Components/DeatailsJobsPostOne/DeatailsJobsPostOne")
);

const LazyDetailsJobsPostTwo = lazy(() =>
  import("./Components/DeatailsJobsPostTwo/DetailsJobsPostTwo")
);

const LazyReviews = lazy(() => import("./Components/Reviews/Reviews"));

const LazyDeatailsJobsPostThree = lazy(() =>
  import("./Components/DeatailsJobsPostThree/DeatailsJobsPostThree")
);

const LazyFeedbackForm = lazy(() =>
  import("./Components/FeedbackForm/FeedbackForm")
);

const LazyThankYouFeedback = lazy(() =>
  import("./Components/FeedbackForm/ThankYouFeedback")
);

const LazyJoblisting = lazy(() => import("./Components/JobListing/Joblisting"));

const LazyAdminDashboard = lazy(() =>
  import("./Components/AdminDashboard/AdminDashboard")
);

const LazyDashboard = lazy(() => import("./Components/Dashboard/Dashboard"));

const LazyMessage = lazy(() => import("./Components/Chat/Message"));

const LazyNotifications = lazy(() => import("./Components/Notifications"));

const LazyPostJobOne = lazy(() =>
  import("./Components/PostJobOne/PostJobOne.jsx")
);

const LazyPostJobTow = lazy(() => import("./Components/PostJobTwo/PostJobTow"));

const LazyPostedJobsList = lazy(() =>
  import("./Components/PostedJobsList/PostedJobsList")
);

const LazyProfilePage = lazy(() =>
  import("./Components/ProfilePage/ProfilePage")
);

const LazyProposal = lazy(() => import("./Components/Proposal/Proposal"));

const LazySignin = lazy(() => import("./Components/Signin/Signin"));

const LazyTermsAndCondition = lazy(() =>
  import("./Components/TermsAndCondition/TermsAndCondition")
);

const LazyTransactionHistory = lazy(() =>
  import("./Components/TransactionHistory")
);

const LazyBuyerList = lazy(() =>
  import("./Components/AdminDashboard/BuyerList/BuyerList")
);

const LazyEditJob = lazy(() => import("./Components/PostJobOne/EditJob"));

const LazyAdminFeedback = lazy(() =>
  import("./Components/AdminDashboard/AdminFeedback/AdminFeedback")
);

const LazyAdminJobs = lazy(() =>
  import("./Components/AdminDashboard/AdminJobs/AdminJobs")
);

const LazyAdminTransactions = lazy(() =>
  import("./Components/AdminDashboard/AdminTransaction/Transactions")
);

const LazySupplierList = lazy(() =>
  import("./Components/AdminDashboard/SupplierList/SupplierList")
);

const LazyUserList = lazy(() =>
  import("./Components/AdminDashboard/UserList/UserList")
);

const LazyUserText = lazy(() => import("./Components/Chat/UserText"));

const LazyVendorProposalText = lazy(() =>
  import("./Components/Chat/VendorProposalText")
);

const LazyForgetPassword = lazy(() =>
  import("./Components/Signin/ForgetPassWord/ForgetPassword")
);

const LazyOtp = lazy(() => import("./Components/Signin/Otp/Otp"));

const LazyRestPassWord = lazy(() =>
  import("./Components/Signin/RestPassword/RestPassWord")
);

const LazySignUpFrm = lazy(() =>
  import("./Components/Signin/SignUpFrm/SignUpFrm")
);

const LazySupplierDetails = lazy(() =>
  import("./Components/AdminDashboard/SupplierList/SupplierDetails")
);
const LazyThankYouPage = lazy(() =>
  import("./Components/Signin/SignUpFrm/ThankYouPage")
);
const LazyFaq = lazy(() => import("./Components/Faq/Faq"));
const LazyJobDetails = lazy(() =>
  import("./Components/JobsCard/JobDetails.jsx")
);
const LazyVendorVerification = lazy(() =>
  import("./Components/VendorVerification/VendorVerification.jsx")
);

export const routes = {
  jobListing: "/requirements",
};
function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="*"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyNotFound />
            </Suspense>
          }
        />

        <Route
          path="/500"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyInternalServerError />
            </Suspense>
          }
        />
        <Route
          path="/job-details/:id"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyJobDetails />
            </Suspense>
          }
        />

        <Route
          path="/"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyWelcomePage />
            </Suspense>
          }
        />
        <Route
          path="/os"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyWelcomePage />
            </Suspense>
          }
        />
        <Route
          path="/mr"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyWelcomePage />
            </Suspense>
          }
        />

        <Route
          path="/signin"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PublicRoute>
                <LazySignin />
              </PublicRoute>
            </Suspense>
          }
        />

        <Route
          path="/forgotpassword"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PublicRoute>
                <LazyForgetPassword />
              </PublicRoute>
            </Suspense>
          }
        />

        <Route
          path="/signup"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PublicRoute>
                <LazySignUpFrm />
              </PublicRoute>
            </Suspense>
          }
        />

        <Route
          path="/reset-password/:uid/:token"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PublicRoute>
                <LazyRestPassWord />
              </PublicRoute>
            </Suspense>
          }
        />

        <Route
          path="/otp/:email"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PublicRoute>
                <LazyOtp />
              </PublicRoute>
            </Suspense>
          }
        />

        <Route
          path="/requirements"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyJoblisting />
            </Suspense>
          }
        />

        <Route
          path="/privacy-policy"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyDataPrivacy />
            </Suspense>
          }
        />

        <Route
          path="/terms-and-conditions"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyTermsAndCondition />
            </Suspense>
          }
        />

        <Route
          path="/transaction-history"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <LazyTransactionHistory />
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/notifications"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyNotifications />
            </Suspense>
          }
        />

        <Route
          path="/vendor-verification"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_client={false}>
                  <LazyVendorVerification />{" "}
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path="/supplier-list"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_staff={true}>
                  <LazySupplierList />{" "}
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/messages"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_staff={true}>
                  <LazyUserList />{" "}
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/admin-transaction"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_staff={true}>
                  <LazyAdminTransactions />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/admin-feedback"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_staff={true}>
                  <LazyAdminFeedback />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/admin-jobs"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_staff={true}>
                  <LazyAdminJobs />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/buyer-list"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_staff={true}>
                  <LazyBuyerList />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/admin-dashboard"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_staff={true}>
                  <LazyAdminDashboard />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path="/supplierproposal/:id"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_client={false}>
                  <LazyProposal />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/profile"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <LazyProfilePage />
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/dashboard"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <LazyDashboard />
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/about-us"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyAboutUs />
            </Suspense>
          }
        />

        <Route
          path="/blog/habot-overview"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyBlog />
            </Suspense>
          }
        />

        <Route
          path="/blog"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyBlogSummary />
            </Suspense>
          }
        />

        <Route
          path="/blog/blog2"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyBlogNavigating />
            </Suspense>
          }
        />

        <Route
          path="/faq"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyFaq />
            </Suspense>
          }
        />

        <Route
          path="/thank-you"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyThankYouPage />
            </Suspense>
          }
        />

        <Route
          path="/accessibility"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyAccessibility />
            </Suspense>
          }
        />

        <Route
          path="/feedback-form"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyFeedbackForm />
            </Suspense>
          }
        />
        <Route
          path="/postjobone"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyPostJobOne />
            </Suspense>
          }
        />
        <Route
          path="/editJob/:id"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_client={true}>
                  <LazyEditJob />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/postjobtwo"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_client={true}>
                  <LazyPostJobTow />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/buyerpostedJobsList"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_client={true}>
                  <LazyPostedJobsList />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/supplierappliedJobsList"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_client={false}>
                  <LazyAppliedJobList />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/viewjobpost/:id"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_client={true}>
                  <LazyDeatailsJobsPostOne />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/reviewproposals/:id"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_client={true}>
                  <LazyDetailsJobsPostTwo />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/hirebuyer/:id"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <ProtectedRoute is_client={true}>
                  <LazyDeatailsJobsPostThree />
                </ProtectedRoute>
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/buyerreviews"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyReviews />
            </Suspense>
          }
        />

        <Route
          path="/supplierreviews"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyBuyerToSupplier />
            </Suspense>
          }
        />

        <Route
          path="/buyer-reviews"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyBuyerReviews />
            </Suspense>
          }
        />
        <Route
          path="/supplier-reviews"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazySupplierReviews />
            </Suspense>
          }
        />

        <Route
          path="/chat/:id/:job"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <LazyMessage />
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/userChat"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <LazyUserText />
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/supplierProposalChat/:id"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <LazyVendorProposalText />
              </PrivateRoute>
            </Suspense>
          }
        />
        <Route
          path="/supplier-details/:id"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <PrivateRoute>
                <LazySupplierDetails />
              </PrivateRoute>
            </Suspense>
          }
        />

        <Route
          path="/thank-you-feedback"
          element={
            <Suspense
              fallback={
                <div>
                  <SpinnerFallback />
                </div>
              }
            >
              <LazyThankYouFeedback />
            </Suspense>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
