export const POPULATE_JOB_POSTING_FORM = "POPULATE_JOB_POSTING_FORM";
export const UPDATE_JOB_POSTING_FORM_ERRORS = "UPDATE_JOB_POSTING_FORM_ERRORS";
export const CLEAR_JOB_POSTING_FORM_DATA = "CLEAR_JOB_POSTING_FORM_DATA";
export const CLEAR_JOB_POSTING_FORM_ERRORS = "CLEAR_JOB_POSTING_FORM_ERRORS";
export const UPDATE_JOB_CATEGORIES = "UPDATE_JOB_CATEGORIES";
export const UPDATE_EMIRATES_NAMES = "UPDATE_EMIRATES_NAMES";
export const UPDATE_TAGS = "UPDATE_TAGS";
export const POPULATE_JOB_APPLICATION_POSTING_FORM_DATA =
  "POPULATE_JOB_APPLICATION_POSTING_FORM_DATA";
export const CLEAR_JOB_APPLICATION_FORM_DATA =
  "CLEAR_JOB_APPLICATION_FORM_DATA";
export const UPDATE_SHOW_PAYMENT_MODAL = "UPDATE_SHOW_PAYMENT_MODAL";
